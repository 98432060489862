import {
  ApiGet,
} from './Api';

/**
 * Payments/Cards API calls
 */
export default () => ({

  /**
   * @returns a promise that contains the current email preferences for the customer
   */
  sessionUrlForSession: (sessionId) => ApiGet({ endpoint: `/session_url_for_session/?session_id=${sessionId}` }),
});
