import { ApiGet, ApiPost } from './Api';

/**
 * Registration API calls
 */
export default () => ({
  /**
   * Registers a client through a link
   * @param {Object} payload the registration payload
   * @returns
   */
  link: (payload) => ApiPost({
    endpoint: 'external_links/register',
    payload,
    timeout: 60000,
  }),

  /**
   * Registers a client through a referral
   * @param {Object} payload the registration payload
   * @returns
   */
  referral: (payload) => ApiPost({
    endpoint: 'referrals/accept_referral',
    payload,
    timeout: 60000,
  }),

  /**
   * Sends a whatsapp message with a verification url for the client
   * @param {String} cellPhone the client's cellphone
   * @returns
   */
  sendVerification: (cellphone, registrationType) => ApiPost({
    endpoint: 'external_links/send_verification',
    payload: { cellphone, registration_type: registrationType },
  }),

  /**
   * Sends a whatsapp message with a verification url for a friend's client
   * @param {String} cellPhone the client's cellphone
   * @returns
   */
  sendReferralInvite: (cellphone) => ApiPost({
    endpoint: 'referrals/send_invite',
    payload: { cellphone },
  }),

  /**
  * Verifies a short URL code.
  *
  * @param {String} uniqueKey - The short URL code.
  * @returns {Promise} - Resolves with the API response.
  */
  verifyCode: (uniqueKey) => ApiPost({
    endpoint: 'external_links/verify_code',
    payload: { uniqueKey },
  }),

  /**
 * Confirms contact verification by sending the code and token to the API.
 *
 * @param {string} code - The verification code.
 * @param {string} token - The verification token.
 * @returns {Promise} - The API response.
 */
  confirmVerification: (code, token) => ApiPost({
    endpoint: 'external_links/confirm_contact_verification',
    payload: { link_token: token, code },
  }),

  /**
 * Checks if a client is verified using the provided link token.
 *
 * @param {string} token - The unique verification token.
 * @returns {Promise} - Resolves with the API response.
 */
  checkVerification: (token) => ApiPost({
    endpoint: 'external_links/check_verification',
    payload: { link_token: token },
  }),

  /**
   * Finds the contact associated with alink token
   * @param {String} cellPhone the client's cellphone
   * @returns
   */
  findContact: (linkToken) => ApiGet({
    endpoint: 'external_links/link_contact',
    params: { link_token: linkToken },
  }),
});
