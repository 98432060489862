import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import '../assets/css/dashboard/dashboardTransactions.scss';

import logoTns from '../assets/img/logo_tns_black.svg';
import logoMarquee from '../assets/img/logo_marquee_black.svg';
import '../assets/css/signIn.scss';
import '../assets/css/registration/postVerification.scss';

import GenericButton from './Shared/GenericButton';
import BeyoutyBonus from '../config/BeyoutyBonus';

import PaymentAPI from '../api/Payment';

function PaymentsCallbackHandler() {
  const color = getComputedStyle(document.documentElement).getPropertyValue('--color-primary');
  const { sessionUrlForSession } = PaymentAPI();

  const [state, setState] = useState({
    successfull: true,
    loading: false,
    session: null,
  });

  const {
    successfull,
  } = state;
  const checkoutSessionHandler = () => {
    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('session_id');
    const cancelSessionId = params.get('cancel_session_id');
    const mode = params.get('mode');

    if (sessionId) {
      setState({ successfull: true, session: { mode: mode || 'setup' } });
      const newUrl = window.location.pathname; // Use the path without query params
      window.history.replaceState({}, '', newUrl);
    }
    if (cancelSessionId) {
      setState({ successfull: false, loading: true });
    }

    if (cancelSessionId) {
      const id = cancelSessionId;
      sessionUrlForSession(id)
        .then((response) => {
          const { data } = response;
          if (data.session) {
            setState({ successfull: data.success, loading: false, session: data.session });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error verifying checkout session:', error);
        });
    }
  };

  useEffect(() => {
    checkoutSessionHandler();
  }, []);

  function successMessage() {
    const history = useHistory();
    const { session } = state;

    const content = session?.mode === 'payment' ? {
      title: 'Your payment was successful',
      message: 'Be sure to book your next visit with us using the button below.',
      btn: {
        text: 'Book Now',
        action: () => history.push('/booking'),
      },
    }
      : {
        title: 'Your payment card has been successfully linked to your account.',
        message: 'You can now pay for your visits via Whatsapp, SMS and Email. Simply let our staff know that you would like to checkout online during your visit. \n\n In the meantime, please download our app, where you’ll have instant access to TNS & Marquee bookings, loyalty and more!',
        btn: {
          text: 'DOWNLOAD APP',
          action: () => window.open(BeyoutyBonus.api.appStorePage),
        },
      };
    return (
      <div>
        <h1 className="text-2xl md:text-4xl font-bold mb-4 text-green-600">Thank You! </h1>
        <p className="text-lg md:text-xl font-bold text-green-600">
          {content.title}
        </p>
        <br />
        <div className="mb-2 text-center" style={{ whiteSpace: 'pre-line' }}>
          {content.message}
        </div>
        <GenericButton
          color={color}
          text={content.btn.text}
          action={content.btn.action}
        />
      </div>
    );
  }

  function handleButtonClick() {
    window.open(state.session.url); // This will open the URL in a new tab
  }

  function failedMessage() {
    const { session } = state;
    const content = session?.mode === 'payment' ? {
      title: 'We were unable to process your transaction.',
      message: 'Please try again or reach out to us at',
    }
      : {

        title: 'We were unable to link your card to your account.',
        message: 'Please try again with a different card, or reach out to us at',
      };
    return (
      <div className="items-center">
        <h1 className="text-2xl md:text-4xl font-bold mb-4 text-red-600 text-center font-bold">We are sorry!</h1>
        <p className="text-lg md:text-xl font-bold text-red-600">
          {content.title}
        </p>
        <div className="mb-2 text-center">
          {content.message}
          <br />
          <a href="mailto:support@beyoutybonus.com" className="text-blue-500 underline">
            support@beyoutybonus.com
          </a>
          <br />
          for further assistance.
        </div>
        <br />
        <GenericButton
          color={color}
          text="Try Again"
          action={handleButtonClick}
        />
      </div>
    );
  }

  function messageContent() {
    return (
      <div className="sign-in-page-container">
        <div className="sign-in-page">
          <div className="logos mt-5 mb-10">
            <div className="pr-5">
              <img className="logo-tns-sign-in" src={logoTns} alt="" />
            </div>
            <div>
              <img className="logo-marquee-sign-in" src={logoMarquee} alt="" />
            </div>
          </div>
          <div className="flex self-center md:w-1/2 sm:px-0 w-full h-screen">
            <div className="flex flex-col px-5 w-full items-center">
              <div className="tab-container">
                <div className="tab">

                  <div className="post-verification-text mb-4 text-center">
                    { successfull ? successMessage() : failedMessage() }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {
      messageContent()
    }
    </div>

  );
}

export default PaymentsCallbackHandler;
