import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Config from '../config/BeyoutyBonus';
import SignIn from './SignIn';
import Booking from './Booking/Booking';
import Activation from './Activation';
import ForgotPassword from './ForgotPassword';
import LogOut from './LogOut';
import Dashboard from './Dashboard/Dashboard';
import DashboardBookings from './Dashboard/DashboardBookings';
import DashboardBookingDetails from './Dashboard/DashboardBookingDetails';
import LoyaltyProgram from './Dashboard/LoyaltyProgram';
import LoyaltyProgramLearnMore from './Dashboard/LoyaltyProgramLearnMore';
import DashboardVouchers from './Dashboard/Vouchers/DashboardVouchers';
import DashboardVoucherDetails from './Dashboard/Vouchers/DashboardVoucherDetails';
import DashboardSurveyDetails from './Dashboard/DashboardSurveyDetails';
import DashboardTransactions from './Dashboard/DashboardTransactions';
import DashboardTransactionDetails from './Dashboard/DashboardTransactionDetails';
import DashboardPackages from './Dashboard/DashboardPackages';
import DashboardGiftCards from './Dashboard/DashboardGiftCards';
import DashboardStampCards from './Dashboard/Stamps/DashboardStampCards';
import DashboardStampCardDetails from './Dashboard/Stamps/DashboardStampCardDetails';
import DashboardWallet from './Dashboard/DashboardWallet';
import Messages from './Dashboard/Messages';
import ErrorPage from './ErrorPage';
import Settings from './Settings/Settings';
import Help from './Settings/Help';
import CommunicationPreferences from './Settings/CommunicationPreferences';
import Profile from './Settings/Profile';
import ChangePassword from './Settings/ChangePassword';
import InviteFriend from './Referral/InviteFriend';
import NonOrganicRegistration from './Registration/NonOrganicRegistration';
import AccountVerification from './Registration/AccountVerification';
import PostVerification from './Registration/PostVerification';
import PrivacyPolicy from './PrivacyPolicy';

import withToast from './Shared/withToast';
import LocalStorageService from './LocalStorageService';
import { setSSOToken } from '../actions/user';
import getSSOToken from '../utils/sessions';
import ClientImpersonationList from './Impersonation/ClientImpersonationList';
import withAppStoreRedirect from './Shared/withAppStoreRedirect';
import VerifyShortUrl from './Registration/VerifyShortUrl';
import ContactVerification from './Registration/ContactVerification';
import PaymentsCallbackHandler from './PaymentsCallbackHandler';

function App() {
  return (
    <Router>
      <Switch>
        <PublicRoute restricted component={SignIn} exact path="/" />
        <PublicRoute restricted component={SignIn} exact path="/clients/sign_in" />
        <PublicRoute restricted component={AccountVerification} exact path="/verification" />
        <PublicRoute restricted component={PostVerification} exact path="/verification-info" />
        <PublicRoute restricted component={Activation} exact path="/activation" />
        <PublicRoute restricted component={ForgotPassword} exact path="/forgot-password" />
        <PublicRoute restricted component={NonOrganicRegistration} exact path="/registration" />
        <PublicRoute restricted component={PrivacyPolicy} exact path="/privacy-policy" />
        <PrivateRoute component={LogOut} exact path="/logout" name="LogOut" />
        <PrivateRoute component={Dashboard} exact path="/dashboard" name="Dashboard" />
        <PrivateRoute component={DashboardBookings} exact path="/dashboard/bookings" name="DashboardBookings" />
        <PrivateRoute component={DashboardBookingDetails} exact path="/dashboard/bookings/:id" name="DashboardBookingDetails" />
        <PrivateRoute component={DashboardVouchers} exact path="/dashboard/vouchers" name="DashboardVouchers" />
        <PrivateRoute component={DashboardVoucherDetails} exact path="/dashboard/vouchers/:id" name="DashboardVoucherDetails" />
        <PrivateRoute component={LoyaltyProgram} exact path="/loyalty-program" name="LoyaltyProgram" />
        <PrivateRoute component={LoyaltyProgramLearnMore} exact path="/loyalty-program-learn-more" name="LoyaltyProgramLearnMore" />
        <PrivateRoute component={DashboardTransactions} exact path="/dashboard/transactions" name="DashboardTransactions" />
        <PrivateRoute component={DashboardTransactionDetails} exact path="/dashboard/transactions/:id" name="DashboardTransactionDetails" />
        <PrivateRoute component={DashboardSurveyDetails} exact path="/dashboard/surveys/:id" name="DashboardSurveyDetails" />
        <PrivateRoute component={Booking} exact path="/booking" name="Booking" />
        <PrivateRoute component={DashboardPackages} exact path="/dashboard/packages" name="DashboardPackages" />
        <PrivateRoute component={DashboardGiftCards} exact path="/dashboard/giftcards" name="DashboardGiftCards" />
        <PrivateRoute component={DashboardStampCards} exact path="/dashboard/stampcards" name="DashboardStampCards" />
        <PrivateRoute component={DashboardStampCardDetails} exact path="/dashboard/stampcards/:id" name="DashboardStampCardDetails" />
        <PrivateRoute component={DashboardWallet} exact path="/dashboard/wallet" name="DashboardWallet" />
        <PrivateRoute component={Messages} exact path="/dashboard/messages" name="Messages" />
        <PrivateRoute component={Settings} exact path="/settings" name="Settings" />
        <PrivateRoute component={CommunicationPreferences} exact path="/settings/communications" name="CommunicationPreferences" />
        <PrivateRoute component={Help} exact path="/settings/help" name="Help" />
        <PrivateRoute component={Profile} exact path="/settings/profile" name="Profile" />
        <PrivateRoute component={ChangePassword} exact path="/settings/changePassword" name="ChangePassword" />
        <PrivateRoute component={InviteFriend} exact path="/invite-friend" name="InviteFriend" />
        <AdminRoute component={ClientImpersonationList} exact path="/client-impersonation" name="ClientImpersonationList" />
        <PublicRoute component={ContactVerification} exact path="/contact_verification" />
        <PublicRoute component={PaymentsCallbackHandler} exact path="/payments" />
        <PublicRoute component={VerifyShortUrl} exact path="/:code" />
        <PublicRoute restricted component={SignIn} exact path="*" />
      </Switch>
    </Router>
  );
}

function isComponentHidden(componentName) {
  return Config.config.hiddenComponents.includes(componentName);
}

function PublicRoute({ component: Component, restricted, ...rest }) {
  const headers = LocalStorageService.getObject('headers');
  const error = useSelector((reduxState) => reduxState.errorMessage);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (error) {
          return <ErrorPage errorTitle={error.errorTitle} errorMessage={error.errorMessage} />;
        }

        if (headers && headers['access-token'] && restricted) {
          return <Redirect to="/dashboard" />;
        }

        return <Component {...props} />;
      }}
    />
  );
}

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  restricted: PropTypes.bool.isRequired,
};

function PrivateRoute({ component: Component, ...rest }) {
  const dispatch = useDispatch();
  const headers = LocalStorageService.getObject('headers');
  const error = useSelector((reduxState) => reduxState.errorMessage);
  const ssoToken = getSSOToken();
  const checkHeaders = headers && headers['access-token'];

  if (ssoToken) {
    dispatch(setSSOToken(ssoToken));
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (error) {
          return <ErrorPage errorTitle={error.errorTitle} errorMessage={error.errorMessage} />;
        }

        if ((checkHeaders || ssoToken) && (!isComponentHidden(rest.name))) {
          return <Component {...props} />;
        }

        // Disable es-lint for simplicity and readability: since props.history is not one prop types, eslint complains
        // but in order to fix that offense we would have to define another component just for this render props.
        // eslint-disable-next-line
        return <Redirect to={{ pathname: '/clients/sign_in', state: { nextPage: props.history.location.pathname } }} />;
      }}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

function AdminRoute({ component: Component, ...rest }) {
  const dispatch = useDispatch();
  const headers = LocalStorageService.getObject('headers');
  const error = useSelector((reduxState) => reduxState.errorMessage);
  const ssoToken = getSSOToken();
  const checkHeaders = headers && headers['access-token'];
  const isAdmin = Config.api.adminList.includes(headers && headers.uid);

  if (ssoToken) {
    dispatch(setSSOToken(ssoToken));
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (error) {
          return <ErrorPage errorTitle={error.errorTitle} errorMessage={error.errorMessage} />;
        }

        if ((checkHeaders || ssoToken) && isAdmin && (!isComponentHidden(rest.name))) {
          return <Component {...props} />;
        }

        // Disable es-lint for simplicity and readability: since props.history is not one prop types, eslint complains
        // but in order to fix that offense we would have to define another component just for this render props.
        // eslint-disable-next-line
        return <Redirect to="/dashboard" />;
      }}
    />
  );
}

AdminRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default compose(
  withToast,
  withAppStoreRedirect,
)(App);
